import algoliasearch from 'algoliasearch/lite';
import getConfig from 'next/config';
import React from 'react';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { ALGOLIA_APP_ID, ALGOLIA_PUBLIC_KEY } from '../../utils/constants';
import SearchField from './SearchField';
import onSearchSelect from './onSearchSelect';

const { publicRuntimeConfig } = getConfig();
const { ALGOLIA_MARKETPLACE_INDEX } = publicRuntimeConfig;

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_PUBLIC_KEY);

interface Props {
  placeholder?: string;
  id?: string;
  labelId?: string;
  inputId?: string;
  menuId?: string;
}

const Search: React.FC<Props> = ({
  placeholder,
  id,
  labelId,
  inputId,
  menuId
}) => {
  return (
    <InstantSearch
      indexName={ALGOLIA_MARKETPLACE_INDEX}
      searchClient={searchClient}
    >
      <SearchField
        onSelect={(app, hit) => {
          onSearchSelect({
            safeName: app?.safeName?.[0],
            appId: app?.appId,
            appType: app?.type,
            hit: hit
          });
        }}
        placeholder={placeholder}
        id={id}
        labelId={labelId}
        inputId={inputId}
        menuId={menuId}
      />
      <Configure clickAnalytics analyticsTags={['marketplace']} />
    </InstantSearch>
  );
};

export default Search;
