import { style } from '@do/walrus';
import { HighlightProvided, Hit } from 'react-instantsearch-core';
import { connectHighlight } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { AlgoliaSearchResult } from '../../interfaces/AlgoliaSearch';

export const Mark = styled.mark`
  background-color: ${style.colors.searchHighlight};
`;

interface Props extends HighlightProvided<AlgoliaSearchResult> {
  hit: Hit<AlgoliaSearchResult>;
  // attribute is the path to the highlighted value, e.g., "name" or "app.customData.tags[0]"
  // https://www.algolia.com/doc/api-reference/widgets/highlight/react/#exposed-props
  attribute: string;
  className: string;
}

const Highlight: React.FC<Props> = ({
  highlight,
  attribute,
  hit,
  className
}) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit
  });

  return (
    <span className={className}>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <Mark key={index}>{part.value}</Mark>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </span>
  );
};

export default connectHighlight(Highlight);
