import { Icon, IconSvg, style } from '@do/walrus';
import React from 'react';
import styled from 'styled-components';
import arrowIcon from '../icons/arrow-keys.svg?sprite';
import escapeIcon from '../icons/escape.svg?sprite';
import returnIcon from '../icons/return.svg?sprite';

const InstructionsBar = styled.ul`
  background: white;
  color: ${style.colors.grey.dark};
  font-size: ${style.vars.fontSize.small};
  border-color: ${style.colors.primary.base};
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 ${style.vars.borderRadius.base}
    ${style.vars.borderRadius.base};
  padding: ${style.vars.space['2']} ${style.vars.space['3']};
  margin-top: 0;
  user-select: none;
`;

const Instruction = styled.li`
  display: inline;
  margin-right: ${style.vars.space['4']};

  ${IconSvg} {
    font-size: ${style.vars.fontSize.xsmall};
    margin-right: ${style.vars.space['1']};
  }
`;

const SearchFooter: React.FC = () => (
  <InstructionsBar>
    <Instruction>
      <Icon xlinkHrefPrefix="" icon={arrowIcon.id} /> navigate
    </Instruction>
    <Instruction>
      <Icon xlinkHrefPrefix="" icon={returnIcon.id} /> go
    </Instruction>
    <Instruction>
      <Icon xlinkHrefPrefix="" icon={escapeIcon.id} /> exit
    </Instruction>
  </InstructionsBar>
);

export default SearchFooter;
