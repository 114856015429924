import { trackEvent } from '@do/seashell';
import { Icon, style } from '@do/walrus';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import _get from 'lodash/get';
import React, { useEffect } from 'react';
import { SearchBoxProvided } from 'react-instantsearch-core';
import { connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { AlgoliaApp } from '../../interfaces/AlgoliaSearch';
import { ALGOLIA_TRACK_CLICK_EVENT } from '../../utils/constants';
import { gaTrackPage, trackProductSearch } from '../../utils/tracking';
import SearchFooter from './SearchFooter';
import SearchResultsList from './SearchResultsList';
import { HitProps } from './onSearchSelect';

const SearchBox = styled.div`
  color: ${style.colors.grey.dark};
  z-index: ${style.vars.zIndex.base};
  position: relative;

  input[type='text'] {
    padding-left: ${style.vars.space['7']};
  }
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  z-index: ${style.vars.zIndex.base};
  left: ${style.vars.space['3']};
  top: 50%;
  margin-top: -10px;
  font-size: 18px;
`;

const SearchLabel = styled.label`
  display: none;
`;

const OpenMenuContainer = styled.div`
  position: absolute;
  width: 100%;
`;

interface Props extends SearchBoxProvided {
  onSelect: (app: AlgoliaApp, hit: HitProps) => void;
  placeholder?: string;
  id?: string;
  labelId?: string;
  inputId?: string;
  menuId?: string;
}

const DEBOUNCE_AMOUNT = 50;
const SEARCH_DEBOUNCE_AMOUNT = 500;

const SearchField: React.FC<Props> = ({
  onSelect,
  placeholder,
  id,
  labelId,
  inputId,
  menuId,
  currentRefinement,
  refine
}) => {
  const trackSearchInput = debounce(() => {
    const input = currentRefinement;
    if (input) {
      // segment
      trackEvent('Search', { input });

      // google analytics site search virtual page
      // There is no /search page, but site search
      // needs one for it to work.
      // see MP-465 for more info
      gaTrackPage(`/search?q=${input}`);
    }
  }, SEARCH_DEBOUNCE_AMOUNT);

  const onStateChange = debounce(({ inputValue }) => {
    if (typeof inputValue !== 'undefined') {
      refine(inputValue);

      trackSearchInput();
    }
  }, DEBOUNCE_AMOUNT);

  useEffect(() => {
    return () => onStateChange.cancel();
  });

  const onSelectResult = (hit) => {
    refine();

    const app: AlgoliaApp = _get(hit, 'marketplace');
    const hitQuery = {
      objectId: _get(hit, 'objectID'),
      queryId: _get(hit, '__queryID'),
      position: _get(hit, '__position')
    };

    trackProductSearch(ALGOLIA_TRACK_CLICK_EVENT, 'click', hitQuery);
    onSelect(app, hitQuery);
  };

  return (
    <SearchBox>
      <Downshift
        id={id}
        labelId={labelId}
        inputId={inputId}
        menuId={menuId}
        onStateChange={onStateChange}
        onSelect={onSelectResult}
        itemToString={() => ''}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          highlightedIndex,
          isOpen,
          openMenu
        }) => (
          <div>
            <SearchLabel {...getLabelProps()}>Search</SearchLabel>
            <SearchIcon icon="search" />
            <input
              {...getInputProps({
                placeholder: placeholder || 'Search',
                onFocus: openMenu,
                type: 'text'
              })}
            />
            {isOpen && (
              <OpenMenuContainer data-cy="search-results-container">
                <SearchResultsList
                  {...{
                    inputValue: currentRefinement,
                    highlightedIndex,
                    getItemProps,
                    indexContextValue: '',
                    hits: []
                  }}
                />
                <SearchFooter />
              </OpenMenuContainer>
            )}
          </div>
        )}
      </Downshift>
    </SearchBox>
  );
};

export default connectSearchBox(SearchField);
